import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// Importar los archivos PDF
import PagoPrimaria from '../documents/Pagoprimaria.pdf';
import PagoSecundaria from '../documents/Pagosecundaria.pdf';
import PagoPrepa from '../documents/Pagoprepa.pdf';
import PagoUniversidad from '../documents/Pagouniversidad.pdf';

const MultiStepForm = () => {
    const [formStepsNum, setFormStepsNum] = useState(0);
    const [formData, setFormData] = useState({
        Nombre: '',
        Apedillidos: '',
        Email: '',
        Telefono: '',
        Fecha: '',
        CURP: '',
        Calle: '',
        Cp: '',
        Ncasa: '',
        Colonia: '',
        Estado: '',
        MD: '',
        Edu: '',
        educationS: ''
    });

    useEffect(() => {
        mostraralerta();
    }, []);

    const mostraralerta = () => {
        Swal.fire({
            icon: 'info',
            title: 'CERTIFICATE MEXICO',
            html: '<p>Los datos del formulario y del remitente de pago deben coincidir con los del solicitante, ya que somos una empresa sin fines de lucro, los certificados o títulos expedidos están registrados ante la Secretaría de Educación Pública. Para mayores informes, envíe un correo electrónico a <b>soporte@certificatemexico.org</b>. El enlace del examen de acreditación de estudios se enviará al correo electrónico que proporcionó. Una vez aprobado el examen, el certificado será enviado vía paquetería al domicilio registrado y puede tardar desde 1 hasta 6 meses en caso de licenciatura</p>',
            footer: '<b>soporte@certificatemexico.org</b>',
            timer: 23000
        });
    };

    const updateFormSteps = (newStep) => {
        const formSteps = document.querySelectorAll('.form-step');
        formSteps.forEach((formStep) => {
            formStep.classList.contains('active') && formStep.classList.remove('active');
        });
        formSteps[newStep].classList.add('active');
    };

    const updateProgressbar = (newStep) => {
        const progressSteps = document.querySelectorAll('.progress-step');
        progressSteps.forEach((progressStep, idx) => {
            if (idx < newStep + 1) {
                progressStep.classList.add('active');
            } else {
                progressStep.classList.remove('active');
            }
        });

        const progressActive = document.querySelectorAll('.progress-step.active');
        const progress = document.getElementById('progress');
        progress.style.width = ((progressActive.length - 1) / (progressSteps.length - 1)) * 100 + '%';
    };

    const handleNext = () => {
        if (validateStep(formStepsNum)) {
            setFormStepsNum((prev) => {
                const newStep = prev + 1;
                updateFormSteps(newStep);
                updateProgressbar(newStep);
                return newStep;
            });
        }
    };

    const handlePrevious = () => {
        setFormStepsNum((prev) => {
            const newStep = prev - 1;
            updateFormSteps(newStep);
            updateProgressbar(newStep);
            return newStep;
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handlePagar = () => {
        // Descargar el archivo PDF basado en la selección del usuario
        const selectedEducation = formData.educationS;

        let fileUrl;
        switch (selectedEducation) {
            case 'primaria':
                fileUrl = PagoPrimaria;
                break;
            case 'secundaria':
                fileUrl = PagoSecundaria;
                break;
            case 'prepa':
                fileUrl = PagoPrepa;
                break;
            case 'universidad':
                fileUrl = PagoUniversidad;
                break;
            default:
                fileUrl = '';
        }

        if (fileUrl) {
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = fileUrl.split('/').pop();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        Swal.fire({
            icon: 'success',
            title: 'CERTIFICATE MEXICO',
            text: 'Te registraste exitosamente',
            confirmButtonText: 'OK'
        }).then(() => {
            resetForm();
        });
    };

    const resetForm = () => {
        setFormData({
            Nombre: '',
            Apedillidos: '',
            Email: '',
            Telefono: '',
            Fecha: '',
            CURP: '',
            Calle: '',
            Cp: '',
            Ncasa: '',
            Colonia: '',
            Estado: '',
            MD: '',
            Edu: '',
            educationS: ''
        });
        setFormStepsNum(0);
        updateFormSteps(0);
        updateProgressbar(0);
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Evitar que el formulario se envíe
    };

    const validateStep = (step) => {
        const fields = [
            ['Nombre', 'Apedillidos', 'Email', 'Telefono', 'Fecha', 'CURP'],
            ['Calle', 'Cp', 'Ncasa', 'Colonia', 'Estado', 'MD'],
            ['Edu', 'educationS']
        ];
        return fields[step].every(field => formData[field]);
    };

    return (
        <form onSubmit={handleSubmit} className="form">
            <h1 className="text-center">Registro</h1>
    
            <div className="progressbar">
                <div className="progress" id="progress"></div>
                <div className="progress-step active" data-title="Registro"></div>
                <div className="progress-step" data-title="Dirección"></div>
                <div className="progress-step" data-title="Educación"></div>
                <div className="progress-step" data-title="Pago"></div>
            </div>
    
            <div className="form-step active">
                <div className="input-group">
                    <label htmlFor="Nombre">Nombre:</label>
                    <input type="text" name="Nombre" id="Nombre" placeholder="Nombres" required value={formData.Nombre} onChange={handleChange} />
                </div>
                <div className="input-group">
                    <label htmlFor="Apedillidos">Apellidos:</label>
                    <input type="text" name="Apedillidos" id="Apedillidos" placeholder="Apellidos" required value={formData.Apedillidos} onChange={handleChange} />
                </div>
    
                <div className="input-group">
                    <label htmlFor="Email">Email:</label>
                    <input type="email" name="Email" id="Email" placeholder="Correo" required value={formData.Email} onChange={handleChange} />
                </div>
    
                <div className="input-group">
                    <label htmlFor="Telefono">Teléfono:</label>
                    <input type="tel" name="Telefono" id="Telefono" placeholder="Teléfono" required value={formData.Telefono} onChange={handleChange} />
                </div>
    
                <div className="input-group">
                    <label htmlFor="Fecha">Fecha de Nacimiento:</label>
                    <input type="date" name="Fecha" id="Fecha" required value={formData.Fecha} onChange={handleChange} />
                </div>
    
                <div className="input-group">
                    <label htmlFor="CURP">CURP:</label>
                    <input type="text" name="CURP" id="CURP" placeholder="CURP" required value={formData.CURP} onChange={handleChange} />
                </div>
    
                <div>
                <button type="button" className="btn btn-next with-50 ml-auto" onClick={handleNext}>Siguiente</button>
                </div>
            </div>
    
            <div className="form-step">
                <div className="input-group">
                    <label htmlFor="Calle">Calle:</label>
                    <input type="text" name="Calle" id="Calle" placeholder="Calle" required value={formData.Calle} onChange={handleChange} />
                </div>
                <div className="input-group">
                    <label htmlFor="Cp">Código Postal:</label>
                    <input type="tel" name="Cp" id="Cp" placeholder="Código Postal" required value={formData.Cp} onChange={handleChange} />
                </div>
                <div className="input-group">
                    <label htmlFor="Ncasa">Número Exterior:</label>
                    <input type="tel" name="Ncasa" id="Ncasa" placeholder="Número Exterior" required value={formData.Ncasa} onChange={handleChange} />
                </div>
                <div className="input-group">
                    <label htmlFor="Colonia">Colonia:</label>
                    <input type="text" name="Colonia" id="Colonia" placeholder="Colonia" required value={formData.Colonia} onChange={handleChange} />
                </div>
                <div className="input-group">
                    <label htmlFor="Estado">Estado:</label>
                    <input type="text" name="Estado" id="Estado" placeholder="Estado" required value={formData.Estado} onChange={handleChange} />
                </div>
                <div className="input-group">
                    <label htmlFor="MD">Municipio/Delegación:</label>
                    <input type="text" name="MD" id="MD" placeholder="Municipio, Delegación" required value={formData.MD} onChange={handleChange} />
                </div>
                <div className="btns-group">
                    <button type="button" className="btn btn-pre" onClick={handlePrevious}>Anterior</button>
                    <button type="button" className="btn btn-next" onClick={handleNext}>Siguiente</button>
                </div>
            </div>
    
            <div className="form-step">
                <div className="input-group">
                    <label htmlFor="Edu">Nivel de Educación:</label>
                    <input type="text" name="Edu" id="Edu" placeholder="Educación" required value={formData.Edu} onChange={handleChange} />
                </div>
                <div className="input-group">
                    <label htmlFor="educationS">Selecciona el Certificado/Título</label>
                    <select name="educationS" id="educationS" required value={formData.educationS} onChange={handleChange}>
                        <option value="">Selecciona una opción</option>
                        <option value="primaria">Primaria $1500 MN</option>
                        <option value="secundaria">Secundaria $2500 MN</option>
                        <option value="prepa">Preparatoria $3500 MN</option>
                        <option value="universidad">Universidad $25000 MN</option>
                    </select>
                </div>
                <div className="btns-group">
                    <button type="button" className="btn btn-pre" onClick={handlePrevious}>Anterior</button>
                    <button type="button" className="btn" onClick={handlePagar}>Pagar</button>
                </div>
            </div>
        </form>
    );  
};

export default MultiStepForm;