import './App.css';
import MultiStepForm from './components/MultiStepForm';

function App() {
  return (
       <MultiStepForm />
  );
}

export default App;
